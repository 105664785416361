import React from 'react'
import ContentLoader from 'react-content-loader'

const LeadTemplate = props => (
  <ContentLoader
    {...props}
    height={180}
    width={300}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="10" y="1" rx="1" ry="1" width="55" height="4" />
    <rect x="70" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="122" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="178" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="234" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="10" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="44" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="44" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="44" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="44" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="44" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="84" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="84" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="84" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="84" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="84" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="124" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="124" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="124" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="124" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="124" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="10" y="164" rx="1" ry="1" width="45" height="3" />
    <rect x="70" y="164" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="164" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="164" rx="1" ry="1" width="45" height="3" />
    <rect x="234" y="164" rx="1" ry="1" width="45" height="3" />
  </ContentLoader>
)
export default LeadTemplate
