import React from 'react'

// compositions
import PrivateRoute from '@components/PrivateRoute'
import Layout from '@compositions/Layout'
import ImpersonationPage from '@compositions/Impersonation'

import { USER_ROLES, IMPERSONATOR_ROLE } from '@modules/constants'

const breadcrumbs = [
  {
    key: 'impersonation',
    text: (
      <>
        <span className="co-gray-3">Account / </span>
        <span>Impersonate User</span>
      </>
    ),
    url: '/impersonation'
  }
]

const AccountPage = () => (
  <Layout
    className="baco-white"
    breadcrumbs={breadcrumbs}
    flagName="impersonation"
  >
    <PrivateRoute
      userType={USER_ROLES}
      roles={[IMPERSONATOR_ROLE]}
      component={ImpersonationPage}
    />
  </Layout>
)

export default AccountPage
