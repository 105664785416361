import React from 'react'
import { string, func, bool } from 'prop-types'
import cx from 'classnames'

// components
import Icon from '@components/Icon'

// styles
import styles from './_.module.scss'

const InputSearch = ({
  value,
  clear,
  withResults,
  helpText,
  disabled,
  onChange,
  onClear
}) => (
  <div className={cx('wi-100', styles.searchWrapper)}>
    <Icon
      className={cx(styles.icon, styles.searchIcon)}
      name="search"
      size={14}
    />
    <input
      maxLength="80"
      type="text"
      placeholder={helpText}
      name="search"
      className={cx('fosi-2 bowi-0 wi-100', styles.searchInput, {
        [styles.searchActive]: value.length > 0,
        [styles.withSearchResults]: withResults
      })}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
    {clear && (
      <div className={cx(styles.icon, styles.closeIcon)} onClick={onClear}>
        Clear
        <Icon name="close" size={10} />
      </div>
    )}
  </div>
)

InputSearch.propTypes = {
  onChange: func.isRequired,
  helpText: string,
  disabled: bool,
  clear: bool,
  withResults: bool,
  value: string,
  onClear: func
}

InputSearch.defaultProps = {
  helpText: '',
  disabled: false,
  clear: false,
  withResults: false,
  value: '',
  onClear: () => null
}

export default InputSearch
