import React from 'react'
import { any, object, string } from 'prop-types'
import cx from 'classnames'

// styles
import styles from './_.module.scss'

const ScrollableContainer = ({ children, style, className }) => {
  return (
    <section className={cx(styles.container, className, 'pal-2')} style={style}>
      <div className={styles.content}>
        {children}
      </div>
    </section>
  )
}

ScrollableContainer.propTypes = {
  children: any,
  style: object,
  className: string
}

ScrollableContainer.defaultProps = {
  children: null,
  style: {},
  className: ""
}

export default ScrollableContainer