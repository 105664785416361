import Code from './components/Code'
import Card from './components/Card'
import MediaObject from './components/MediaObject'
import Paragraph from './components/Paragraph'
import Table from './components/Table'
import GridWidget from './components/GridWidget'
import ListWidget from './components/ListWidget'
import Grid from './components/Grid'
import Dots from './components/Dots'

export default {
  Code,
  Paragraph,
  MediaObject,
  Table,
  GridWidget,
  ListWidget,
  Grid,
  Card,
  Dots
}
