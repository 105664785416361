import React, { useState } from 'react'
import cx from 'classnames'

// Components
import { ToastNotify } from '@compositions/Toast'
import InputSearch from './components/InputSearch'
import Loader from '@components/Loader'
import ScrollableContainer from '@components/ScrollableContainer'

// Modules
import {
  REST_API_ENDPOINTS,
  API_VERSION,
  IMPERSONATION_COOKIE_NAME,
  USER_ROLES,
  ADMIN_ROLE
} from '@modules/constants'
import Auth from '@modules/auth'
import { secondsToMilliseconds } from '@modules/time'
import { Collection } from '@modules/rest-api'
import Optimizer from '@modules/optimizer'
import Router from '@modules/router'
import UserRoles from '@modules/userRoles'

// styles
import styles from './_.module.scss'

const EXPIRES_IN_SECONDS = 1600

const usersCollection = new Collection(
  REST_API_ENDPOINTS.usersImpersonation,
  API_VERSION
)

const handleInputChange = Optimizer.debounce(
  ({ value, setLoading, setResults }) => {
    if (value.length > 2) {
      const config = {
        apiVersion: API_VERSION,
        params: {
          search: value,
          itemsPerPage: 300
        },
        headers: {
          skipImpersonation: true
        }
      }

      usersCollection.readSigned(config, (err, res) => {
        setLoading(false)
        if (err) return console.error(err)
        const data = res.data || {}
        const results = data.results || []
        setResults(results)
      })
    }
  },
  2000
)

function handleSelectClick(item) {
  return function() {
    Auth.clientSetSession(
      IMPERSONATION_COOKIE_NAME,
      {
        ...item,
        accessToken: item.id,
        _expiresAtAsMilliseconds:
          Date.now() + secondsToMilliseconds(EXPIRES_IN_SECONDS)
      },
      () => {
        const notificationId = ToastNotify.info(
          'Refreshing roles...'
        )
        UserRoles.hasNeededRoles(
          USER_ROLES,
          [ADMIN_ROLE],
          () => {
            ToastNotify.remove(notificationId)
            ToastNotify.success(
              `Impersonating user ${item.firstName} ${item.lastName}`
            )
            Router.go(`/dashboard`)
          },
          true
        )
      }
    )
  }
}

const Impersanation = () => {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [results, setResults] = useState([])
  const noResults = inputValue && !results.length && !loading
  return (
    <>
      <InputSearch
        isActive={true}
        type="text"
        helpText="Search"
        value={inputValue}
        clear={inputValue.length || noResults}
        withResults={loading || results.length || noResults}
        onClear={() => {
          setInputValue('')
          setResults([])
        }}
        onChange={({ currentTarget: { value } }) => {
          if (value.length <= 80) {
            setInputValue(value)
            setLoading(value.length > 2)
            if (results.length > 0) setResults([])
            handleInputChange({ value, setLoading, setResults })
          }
        }}
      />
      {loading && (
        <div className={styles.resultsWrapper}>
          <ul>
            <li className={styles.loadingResult}>
              <Loader.Dots />
            </li>
          </ul>
        </div>
      )}
      {(results.length > 0 || noResults) && (
        <ScrollableContainer
          style={{ maxHeight: '310px' }}
          className={cx(styles.resultsWrapper, styles.scrollableContainer)}
        >
          <ul>
            {results.map(result => {
              const {
                id,
                email,
                firstName,
                lastName,
                organizationName
              } = result
              return (
                <li
                  key={id}
                  className={styles.result}
                  onClick={handleSelectClick(result)}
                >
                  <div className="di-f juco-sb">
                    <span className={cx('fosi-2', 'fowe-b', styles.fullName)}>
                      {`${firstName || ''} ${lastName || ''}`}
                    </span>
                    <span className={cx('fosi-2', styles.email)}>
                      {email || ''}
                    </span>
                    <span className={cx('fosi-2', styles.organization)}>
                      {organizationName || ''}
                    </span>
                  </div>
                </li>
              )
            })}
            {noResults && <li className={cx(styles.result, styles.noResults)}>No results found.</li>}
          </ul>
        </ScrollableContainer>
      )}
    </>
  )
}

export default Impersanation
