import React from 'react'
import ContentLoader from 'react-content-loader'
import { oneOfType, string, number, oneOf } from 'prop-types'

const ALIGN_LEFT = 'left'
const ALIGN_RIGHT = 'right'

const FIGURE_NONE = 'none'
const FIGURE_CIRCLE = 'circle'
const FIGURE_SQUARE = 'square'

const MediaObject = ({ height, width, figure, align, ...rest }) => (
  <div style={{ width, height }}>
    <ContentLoader
      height={90}
      width={200}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...rest}
    >
      {align === ALIGN_LEFT ? (
        <>
          <rect x="70" y="15" rx="4" ry="4" width="105" height="6.4" />
          <rect x="70" y="35" rx="3" ry="3" width="120" height="6.4" />
          <rect x="70" y="55" rx="3" ry="3" width="90" height="6.4" />
          {figure === FIGURE_CIRCLE ? (
            <circle cx="35" cy="40" r="30" />
          ) : figure === FIGURE_SQUARE ? (
            <rect x="5" y="13" rx="0" ry="0" width="55" height="54" />
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <rect x="5" y="15" rx="4" ry="4" width="105" height="6.4" />
          <rect x="5" y="35" rx="3" ry="3" width="120" height="6.4" />
          <rect x="5" y="55" rx="3" ry="3" width="90" height="6.4" />
          {figure === FIGURE_CIRCLE ? (
            <circle cx="160" cy="40" r="30" />
          ) : figure === FIGURE_SQUARE ? (
            <rect x="135" y="13" rx="0" ry="0" width="55" height="54" />
          ) : (
            ''
          )}
        </>
      )}
    </ContentLoader>
  </div>
)

MediaObject.propTypes = {
  align: oneOf([ALIGN_LEFT, ALIGN_RIGHT]),
  figure: oneOf([FIGURE_CIRCLE, FIGURE_SQUARE, FIGURE_NONE]),
  height: oneOfType([string, number]),
  width: oneOfType([string, number])
}

MediaObject.defaultProps = {
  align: ALIGN_LEFT,
  figure: FIGURE_SQUARE,
  height: 90,
  width: 200
}

export default MediaObject
