import React from 'react'

// styles
import styles from './_.module.scss'

const DotsLoader = () => {
  return (
    <div className={styles.dotsLoader}>
      <span />
      <span />
      <span />
    </div>
  )
}

export default DotsLoader
