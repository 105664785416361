import React from 'react'
import ContentLoader from 'react-content-loader'

const ListWidget = () => (
  <ContentLoader
    height={210}
    width={300}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="6" y="18" rx="0" ry="0" width="263" height="24" />
    <rect x="6" y="58" rx="0" ry="0" width="264" height="24" />
    <rect x="6" y="98" rx="0" ry="0" width="264" height="24" />
    <rect x="6" y="138" rx="0" ry="0" width="264" height="24" />
  </ContentLoader>
)

export default ListWidget
