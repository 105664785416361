import React from 'react'
import ContentLoader from 'react-content-loader'

const GridWidget = props => (
  <ContentLoader
    {...props}
    height={120}
    width={300}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="6" y="10" rx="4" ry="4" width="117" height="6" />
    <rect x="8" y="30" rx="0" ry="1" width="123" height="24" />
    <rect x="149" y="30" rx="0" ry="1" width="123" height="24" />
    <rect x="8" y="70" rx="0" ry="1" width="123" height="24" />
    <rect x="148" y="70" rx="0" ry="1" width="123" height="24" />
  </ContentLoader>
)

export default GridWidget
