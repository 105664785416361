import React from 'react'
import { string } from 'prop-types'

// components
import Leads from './components/Leads'
import Communities from './components/Communities'

const Grid = ({ template, ...rest }) =>
  template === 'Leads' ? <Leads {...rest} /> : <Communities {...rest} />

Grid.propTypes = {
  template: string
}

Grid.defaultProps = {
  template: 'Leads'
}

export default Grid
