import React from 'react'
import ContentLoader from 'react-content-loader'

const CommunityTemplate = props => (
  <ContentLoader
    {...props}
    height={150}
    width={300}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="10" y="1" rx="1" ry="1" width="55" height="4" />
    <rect x="70" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="122" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="178" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="234" y="1" rx="1" ry="1" width="45" height="4" />
    <rect x="10" y="15" rx="1" ry="1" width="20" height="24" />
    <rect x="35" y="24" rx="1" ry="1" width="28" height="3" />
    <rect x="70" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="24" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="24" rx="1" ry="1" width="45" height="3" />
    <circle cx="255" cy="28" r="5" />
    <rect x="10" y="55" rx="1" ry="1" width="20" height="24" />
    <rect x="35" y="64" rx="1" ry="1" width="28" height="3" />
    <rect x="70" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="64" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="64" rx="1" ry="1" width="45" height="3" />
    <circle cx="255" cy="68" r="5" />
    <rect x="10" y="95" rx="1" ry="1" width="20" height="24" />
    <rect x="35" y="104" rx="1" ry="1" width="28" height="3" />
    <rect x="70" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="104" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="104" rx="1" ry="1" width="45" height="3" />
    <circle cx="255" cy="108" r="5" />
    <rect x="10" y="135" rx="1" ry="1" width="20" height="24" />
    <rect x="35" y="144" rx="1" ry="1" width="28" height="3" />
    <rect x="70" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="122" y="144" rx="1" ry="1" width="45" height="3" />
    <rect x="178" y="144" rx="1" ry="1" width="45" height="3" />
    <circle cx="255" cy="148" r="5" />
  </ContentLoader>
)
export default CommunityTemplate
