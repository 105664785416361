import React, { useState, useEffect } from 'react'
import { any, array, oneOf } from 'prop-types'

// compositions
import { ToastNotify } from '@compositions/Toast'

// modules
import UserRoles from '@modules/userRoles'
import BrowserRouter from '@modules/router'
import { USER_ROLES, IMPERSONATED_USER_ROLES } from '@modules/constants'

const PrivateRoute = ({
  component: Component,
  loadingComponent: LoadingComponent,
  roles: neededRoles,
  userType,
  ...rest
}) => {
  const [hasAccess, setHasAccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    UserRoles.hasNeededRoles(
      userType,
      neededRoles,
      (err, userHasAccess) => {
        if (err) {
          setError(err)
        } else {
          setHasAccess(userHasAccess)
          setLoading(false)
        }
      },
      true
    )
  }, [neededRoles, userType])

  if (error) {
    if (error.message === 'UNAUTHORIZED') {
      BrowserRouter.go('/login')
      return null
    }
    BrowserRouter.go('/dashboard')
    ToastNotify.error(
      `An error has ocurred accessing the page ${rest.path}, please contact the Community Central administrator`
    )
    return null
  }

  if (!loading && !hasAccess) {
    BrowserRouter.go('/403')
    ToastNotify.error(
      `You don't have enough privileges to access the page ${rest.path}, please contact the Community Central administrator`
    )
    return null
  }

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : hasAccess ? (
        <Component {...rest} />
      ) : (
        <p>You don't have enough privileges to access this page</p>
      )}
    </>
  )
}

PrivateRoute.propTypes = {
  roles: array.isRequired,
  component: any.isRequired,
  userType: oneOf([USER_ROLES, IMPERSONATED_USER_ROLES]).isRequired,
  loadingComponent: any
}

PrivateRoute.defaultProps = {
  loadingComponent: () => <p>Checking if you have access to this page...</p>
}

export default PrivateRoute
