import React from 'react'
import ContentLoader from 'react-content-loader'
import { oneOfType, string, number } from 'prop-types'

const Code = ({ height, width, ...rest }) => (
  <div style={{ width, height }}>
    <ContentLoader
      rtl
      height={height}
      width={width}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...rest}
    >
      <rect x="0" y="20" rx="3" ry="3" width="190" height="6.4" />
      <rect x="0" y="40" rx="3" ry="3" width="180" height="6.4" />
      <rect x="0" y="60" rx="3" ry="3" width="185" height="6.4" />
      <rect x="0" y="80" rx="3" ry="3" width="175" height="6.4" />
      <rect x="0" y="100" rx="3" ry="3" width="180" height="6.4" />
      <rect x="0" y="120" rx="3" ry="3" width="140" height="6.4" />
    </ContentLoader>
  </div>
)

Code.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number])
}

Code.defaultProps = {
  height: 90,
  width: 200
}

export default Code
