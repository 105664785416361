export default class Optimizer {
  static debounce(func, time = 200, immediate) {
    let timeoutId

    return function() {
      const context = this,
        args = arguments

      const deferedFunc = function() {
        timeoutId = null
        if (!immediate) func.apply(context, args)
      }

      let shouldInvokeNow = immediate && !timeoutId

      clearTimeout(timeoutId)

      timeoutId = setTimeout(deferedFunc, time)

      if (shouldInvokeNow) {
        func.apply(context, args)
      }
    }
  }
}
