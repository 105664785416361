import React from 'react'
import ContentLoader from 'react-content-loader'
import { oneOfType, string, number } from 'prop-types'

const Card = ({ height, width, ...rest }) => (
  <div style={{ width, height }}>
    <ContentLoader
      height={180}
      width={240}
      speed={1}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...rest}
    >
      <rect x="0" y="0" rx="5" ry="5" width="240" height="180" />
    </ContentLoader>
  </div>
)

Card.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number])
}

Card.defaultProps = {
  height: 180,
  width: 240
}

export default Card
